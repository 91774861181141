import * as THREE from 'three';
import { world } from '../../../World';
import PicturePatch from './PicturePatch';
import BluetoothPatch from './BluetoothPatch';
import CityPatch from './CityPatch';

export default class Patches extends THREE.Group {
  constructor() {
    super();

    this.enabled = false;

    this.picturesCycle = 0;
    this.picturePatches = [];
    this.showPicturePatches = true;

    let counter = 0;
    world.config.json.picturePatches.map((batch) => {
      batch.map((data) => {
        data.cycle = counter;
        const picturePatch = new PicturePatch(data);
        this.add(picturePatch);
        this.picturePatches.push(picturePatch);
      });
      counter++;
    });

    this.bluetoothPatches = [];
    world.config.json.bluetoothPatches.map((data) => {
      const bluetoothMap = new BluetoothPatch(data);
      this.add(bluetoothMap);
      this.bluetoothPatches.push(bluetoothMap);
    });

    this.cityPatches = [];
    this.showCityPatches = false;
    world.config.json.cityPatches.map((data) => {
      // world.config.json.cityPatchesTest.map(({ long, lat }) => {
      const cityPatch = new CityPatch(data);
      this.add(cityPatch);
      this.cityPatches.push(cityPatch);
    });
  }

  playIntro() {
    clearTimeout(this.introTimeoudId);
    this.introTimeoudId = setTimeout(() => {
      // console.log(this);
      this.enabled = true;
    }, 2200);
  }

  cyclePictures(num) {
    this.picturesCycle = num;
    // console.log(this.picturesCycle);

    if (this.picturePatches) {
      for (const picturePatch of this.picturePatches) {
        picturePatch.setCycle(this.picturesCycle);
      }
    }
  }

  update() {
    const { coveragePct, coverageZoomPct } = world.scrollController;

    this.setBluetoothPatches(coveragePct, coverageZoomPct);
    this.setPicturePatches(coveragePct, coverageZoomPct);
    this.setCityPatches(coveragePct, coverageZoomPct);
  }

  setBluetoothPatches(coveragePct, coverageZoomPct) {
    const showBluetoothPatches = this.enabled && coverageZoomPct < 0.5;

    // console.log(showBluetoothPatches);

    if (showBluetoothPatches !== this.showBluetoothPatches) {
      this.showBluetoothPatches = showBluetoothPatches;

      for (const bluetoothPatch of this.bluetoothPatches) {
        if (showBluetoothPatches) {
          bluetoothPatch.show();
        } else {
          bluetoothPatch.hide();
        }
      }
    }
  }

  setPicturePatches(coveragePct, coverageZoomPct) {
    // console.log(coverageZoomPct);

    const showPicturePatches = this.enabled && coveragePct < 0.5;
    if (showPicturePatches !== this.showPicturePatches) {
      this.showPicturePatches = showPicturePatches;

      for (const picturePatch of this.picturePatches) {
        if (showPicturePatches) {
          // picturePatch.show();
          picturePatch.setActive(true);
        } else {
          // picturePatch.hide();
          picturePatch.setActive(false);
        }
      }
    }
  }

  setCityPatches(coveragePct, coverageZoomPct) {
    const showCityPatches = this.enabled && coveragePct >= 0.5 && coverageZoomPct < 0.5;
    if (showCityPatches !== this.showCityPatches) {
      this.showCityPatches = showCityPatches;

      for (const cityPatch of this.cityPatches) {
        if (showCityPatches) {
          cityPatch.show();
        } else {
          cityPatch.hide();
        }
      }
    }
  }
}
