export default class Time {
  constructor(callback) {
    this.callback = callback;

    // setup
    this.start = Date.now();
    this.current = this.start;
    this.elapsed = 0;
    this.delta = 16; //FPS: 1000 / 60

    window.requestAnimationFrame(this.tick.bind(this));
  }

  tick() {
    const currentTime = Date.now();
    this.delta = currentTime - this.current;
    this.current = currentTime;
    this.elapsed = this.current - this.start;

    this.callback && this.callback();

    window.requestAnimationFrame(this.tick.bind(this));
  }
}
