import gsap from 'gsap';
import { world } from '../../World';
import * as THREE from 'three';
import globeVertexShader from './shaders/globe/vertex.glsl?raw';
import globeFragmentShader from './shaders/globe/fragment.glsl?raw';
import Patches from './patches/Patches';
import Lines from './lines/Lines';
import mouse from '../../core/Mouse';

export default class Globe extends THREE.Group {
  constructor() {
    super();

    this.isGlobeOver = false;

    this.inner = new THREE.Mesh(
      new THREE.SphereGeometry(1.5, 60, 60),
      new THREE.MeshMatcapMaterial({
        transparent: true,
        // depthTest: false,
        matcap: world.textureManager.earthGradient,
        polygonOffset: true,
        polygonOffsetFactor: 18,
      })
    );

    this.outer = new THREE.Mesh(
      new THREE.SphereGeometry(1.5, 60, 60),
      new THREE.ShaderMaterial({
        vertexShader: globeVertexShader,
        fragmentShader: globeFragmentShader,
        transparent: true,
        // depthTest: false,
        uniforms: {
          uFrequency: { value: new THREE.Vector2(10, 5) },
          uTime: { value: 0 },
          uShowFactor: { value: 1 },
          uShowCountries: { value: 0 },
          uShowCountrieLines: { value: 0 },
          segU: { value: 0 },
          segV: { value: 54 },
          uSpeedX: { value: 1 },
          uSpeedY: { value: 1 },
          uAlpha: { value: 0.2 },
          wireWidthFactor: { value: 2.5 },
          dpr: { value: world.sizes.pixelRatio },
          wireColor: { value: new THREE.Color('#014FFE') },
          uTexture: { value: world.textureManager.earthMap },
        },
      })
    );

    this.add(this.outer);
    this.add(this.inner);

    const item = this.inner;
    item.userData.enabled = true;
    item.userData.rollOver = this.globeOverHandler.bind(this);
    item.userData.rollOut = this.globeOutHandler.bind(this);
    world.main.caster.addItem(item);

    // this.outer.rotation.y = Math.PI * 1;
    this.outer.rotation.y = -Math.PI * 0.5;

    this.patches = new Patches();
    this.add(this.patches);

    this.lines = new Lines();
    this.add(this.lines); //TEMP UITGEZET

    this.rotation.y = -0.5;

    this.initInteractivity();
  }

  initInteractivity() {
    this.speedX = 0;
    // this.speedY = 0;

    window.addEventListener('mousedown', this.downHandler.bind(this));
    window.addEventListener('mouseup', this.upHandler.bind(this));

    window.addEventListener('touchstart', this.downHandler.bind(this));
    window.addEventListener('touchend', this.upHandler.bind(this));
  }

  downHandler(e) {
    // console.log('this.isGlobeOver', this.isGlobeOver)
    if (this.isGlobeOver) {
      this.mousePos = {
        x: mouse.x,
        y: mouse.y,
      };
      this.isDown = true;
    }
  }

  upHandler(e) {
    this.isDown = false;
  }

  // mousemoveHandler(e) {
  //   console.log(e);
  //   world.time
  // }

  globeOverHandler() {
    // console.log('globeOverHandler');
    this.isGlobeOver = true;
  }

  globeOutHandler() {
    // console.log('globeOutHandler');
    this.upHandler();
    this.isGlobeOver = false;
  }

  playIntro() {
    this.patches.playIntro();
    this.lines.playIntro();

    gsap.to(this.outer.material.uniforms.uShowFactor, { value: 0, duration: 3.5, ease: 'power1.out' });
    gsap.to(this.outer.material.uniforms.uShowCountries, { value: 1, delay: 1.8, duration: 2.3, ease: 'power4.out' });
    gsap.to(this.outer.material.uniforms.uShowCountrieLines, { value: 1, delay: 2.4, duration: 1.5, ease: 'sine.out' });
  }

  overHandler() {
    // console.log('overHandler');
    this.isOver = true;
  }

  outHandler() {
    // console.log('outHandler');
    this.isOver = false;
  }

  update() {
    const currentTime = Date.now();
    this.delta = currentTime - this.current;
    this.current = currentTime;

    if (!this.delta) this.delta = 0;

    let endSpeedX = 0.02;
    let slowFactor = 0.005;

    if (this.isDown) {
      const deltaX = (mouse.x - this.mousePos.x) / this.delta;
      endSpeedX = Math.max(-1, Math.min(1, deltaX * 5));

      this.mousePos = {
        x: mouse.x,
        y: mouse.y,
      };
    }

    if (this.isOver) {
      endSpeedX = 0;
      slowFactor = 0.008;
    }

    this.speedX += (endSpeedX - this.speedX) * slowFactor * this.delta;
    this.rotation.y += this.speedX * 0.01 * this.delta;

    const coverageZoomPct = world.scrollController.coverageZoomPct;
    let alphaPct = 1 - (coverageZoomPct - 0.5) * 2;
    alphaPct = Math.max(0, Math.min(1, alphaPct));

    this.inner.material.opacity = Math.pow(alphaPct, 0.1);

    this.outer.material.uniforms.uAlpha.value = Math.pow(alphaPct, 1);
    this.outer.material.uniforms.uTime.value = world.time.elapsed * 0.000025;

    this.patches.update();
    this.lines.update();
  }
}
