import gsap from 'gsap';
import * as THREE from 'three';

export default class Patch extends THREE.Group {
  constructor() {
    super();

    this.wrapper = new THREE.Group();
    this.add(this.wrapper); 
    this.wrapper.scale.set(0, 0, 0);
  }

  show() {
    const scale = 1;
    gsap.killTweensOf(this.wrapper.scale);
    gsap.to(this.wrapper.scale, {
      delay: Math.random() * 1,
      x: scale,
      y: scale,
      z: scale,
      duration: 1.2,
      ease: 'power3.out',
    });
  }

  hide() {
    const scale = 0;
    gsap.killTweensOf(this.wrapper.scale);
    gsap.to(this.wrapper.scale, {
      delay: Math.random() * 0.3,
      x: scale,
      y: scale,
      z: scale,
      duration: 0.45,
      ease: 'power3.out',
    });
  }
}
