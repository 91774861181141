import gsap from 'gsap';
import { world } from '../../World';
import * as THREE from 'three';

const pos = [
  { x: -8.025, y: -4.825 },
  { x: 6.875, y: -2.75 },
  { x: 4.575, y: 1.375 },
  { x: 1.15, y: 4.8 },
  { x: -6.875, y: 4.125 },
];

export default class Patch extends THREE.Group {
  constructor(index, texture) {
    super();

    const data = pos[index % 5];

    this.position.x = data.x * 0.135;
    this.position.y = data.y * 0.135;

    const bgMaterial = new THREE.SpriteMaterial({ map: world.textureManager.patchGlowMap });
    const bgSprite = new THREE.Sprite(bgMaterial);
    let scale = 0.36;
    bgSprite.scale.set(scale, scale, scale);
    this.add(bgSprite);

    const material = new THREE.SpriteMaterial({ map: texture });
    const sprite = new THREE.Sprite(material);
    scale = 0.17;
    sprite.scale.set(scale, scale, scale);
    this.add(sprite);
  }
}
