import * as THREE from 'three';
import { world } from '../World';

export default class TextureManager {
  constructor(basePath) {
    this.colorMaps = [];

    this.earthGradient = world.textureLoader.load(`${basePath}textures/earth-gradient.webp`);
    this.earthMap = world.textureLoader.load(`${basePath}textures/earth-map.webp`);
    this.earthMap.minFilter = THREE.LinearFilter;

    this.bluetoothMap = world.textureLoader.load(`${basePath}textures/patch_bluetooth-min.png`);
    this.cityMap = world.textureLoader.load(`${basePath}textures/patch_city-min.png`);

    this.patchGlowMap = world.textureLoader.load(`${basePath}textures/patch_glow-min.png`);

    this.hexagonMap = world.textureLoader.load(`${basePath}textures/hexagon.webp`);
    this.hexagonMap.minFilter = THREE.NearestFilter;

    world.config.json.textures.map(({ id, ratio, file }) => {
      this.colorMaps.push({
        id,
        ratio,
        texture: world.textureLoader.load(`${file}`),
      });
    });
  }

  getTexture(id) {
    return this.colorMaps.find((element) => element.id === id);
  }
}
