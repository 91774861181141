import gsap from 'gsap';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { MathUtils } from 'three';
import { world } from '../World';
import mouse from './Mouse';

export default class CameraManager {
  constructor() {
    this.fov = 30;
    this.lerp = 1;

    this.scrollPct = 0;
    this.scrollPctEnd = 0;

    this.useOrbit = true;
  }

  init() {
    // this.scrollConfig = new Config();
    // this.messConfig = new Config();
    // this.messConfig.groupPosition.set(0, 1.2, 9);

    //group
    const group = new THREE.Group();
    world.scene.add(group);
    this.group = group;

    // camera
    const camera = new THREE.PerspectiveCamera(this.fov, world.sizes.width / world.sizes.height, 0.1, 100);
    this.camera = camera;

    if (this.useOrbit) {
      camera.position.set(0, 0, 6);
      world.scene.add(camera);
      this.controls = new OrbitControls(camera, world.canvas);
      this.controls.enableDamping = true;
    } else {
      camera.position.set(0, 0, 6);
      group.add(camera);
    }

    return camera;
  }

  startAnimation() {
    
  }

  scroll(pct) {
    // this.scrollPctEnd = pct;

    //this.timeline.progress(pct);
  }

  resize() {
    //updata camera
    world.camera.aspect = world.sizes.width / world.sizes.height;

    world.camera.updateProjectionMatrix();
  }

  update() {
    //console.log('update');

    //console.log(this.lerp);

    // this.scrollPct += (this.scrollPctEnd - this.scrollPct) * 0.1;
    // this.timeline.progress(this.scrollPct);

    // if (this.useOrbit) {
    //   this.controls && this.controls.update();
    // } else {
    //   this.average(world.camera.position, this.scrollConfig.cameraPosition, this.messConfig.cameraPosition);
    //   this.average(world.camera.rotation, this.scrollConfig.cameraRotation, this.messConfig.cameraRotation);
    //   this.average(this.group.position, this.scrollConfig.groupPosition, this.messConfig.groupPosition);
    //   this.average(this.group.rotation, this.scrollConfig.groupRotation, this.messConfig.groupRotation);

    //   const x = -1 + mouse.slowPctX * 2;
    //   const y = -1 + mouse.slowPctY * 2;

    //   world.camera.rotation.x += -y * 0.1;
    //   world.camera.rotation.y += -x * 0.1;

    //   world.camera.position.x += -x * 0.2;
    //   world.camera.position.y += y * 0.2;
    // }
  }
}

// class Config {
//   constructor() {
//     this.cameraPosition = new THREE.Vector3();
//     this.cameraRotation = new THREE.Euler();
//     this.groupPosition = new THREE.Vector3();
//     this.groupRotation = new THREE.Euler();
//   }
// }
