import * as THREE from 'three';
import { world } from '../../../World';
import Line from './Line';

export default class Lines extends THREE.Group {
  constructor() {
    super();

    this.enabled = false;

    this.lines = [];
    this.show = true;
    world.config.json.lines.map(({ start, end }) => {
      const line = new Line(start, end);
      this.add(line);
      this.lines.push(line);
    });
  }

  playIntro() {
    clearTimeout(this.introTimeoudId);
    this.introTimeoudId = setTimeout(() => {
      // console.log(this);
      this.enabled = true;
    }, 2200);
  }

  update() {
    const { coveragePct, coverageZoomPct } = world.scrollController;

    const show = this.enabled && coveragePct > 0.5 && coverageZoomPct < 0.5;
    if (show !== this.show) {
      this.show = show;
      // console.log(this.show);

      for (const line of this.lines) {
        if (show) {
          line.show();
        } else {
          line.hide();
        }
      }
    }

    for (const line of this.lines) {
      line.update();
    }
  }
}
