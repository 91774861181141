import gsap from 'gsap';
import { world } from '../../World';
import * as THREE from 'three';
import globeVertexShader from './shaders/vertex.glsl?raw';
import globeFragmentShader from './shaders/fragment.glsl?raw';
import Patches from './Patches';

export default class Hexa extends THREE.Group {
  constructor() {
    super();

    world.main.container.add(this); // TEMP UITGEZET

    const plane = new THREE.Mesh(
      new THREE.PlaneGeometry(3.25, 3.25),
      new THREE.ShaderMaterial({
        vertexShader: globeVertexShader,
        fragmentShader: globeFragmentShader,
        transparent: true,
        depthTest: false,
        uniforms: {
          uTime: { value: 0 },
          uZoom: { value: 0 },
          uRatio: { value: 1 },
          uAlpha: { value: 0 },
          uRadius: { value: 0 },
          uLineColor: { value: new THREE.Color('#016cfe') },
          uShineLineColor: { value: new THREE.Color('#82caff') },
          uShineBlurColor: { value: new THREE.Color('#1064ff') },
          uBgColor: { value: new THREE.Color('#030017') },
          dpr: { value: world.sizes.pixelRatio },
          uTexture: { value: world.textureManager.hexagonMap },
        },
      })
    );

    if (world.settings.debug) {
      const folder = world.gui.addFolder('zoom');
      folder.add(plane.material.uniforms.uZoom, 'value').min(0).max(1).step(0.001);
      folder.close();
    }

    this.plane = plane;
    this.add(plane);

    this.patches = new Patches();
    this.add(this.patches);

    this.resize();
  }

  resize() {
    const ratio = window.innerWidth / window.innerHeight;
    this.plane.material.uniforms.uRatio.value = ratio;
    // console.log(ratio);

    this.plane.scale.x = ratio;
  }

  update() {
    this.plane.material.uniforms.uTime.value = world.time.elapsed * 0.0003;

    // SHOW
    const coverageZoomPct = world.scrollController.coverageZoomPct;
    let alphaPct = (coverageZoomPct - 0.75) * 4;
    // alphaPct = Math.max(0, Math.min(1, alphaPct));
    alphaPct = Math.max(0, alphaPct);

    this.plane.material.uniforms.uAlpha.value = Math.min(1, alphaPct);

    // let showPct = Math.pow((1 - alphaPct) * 0.2, 1.2);
    // this.plane.rotation.z = showPct * -1.5;
    // this.plane.position.z = showPct * 10.4;

    // console.log(alphaPct);

    // this.plane.material.uniforms.uRadius.value = 0.9 + Math.pow(alphaPct, 1.45) * 0.95;
    this.plane.material.uniforms.uRadius.value = .95 + Math.pow(alphaPct, 1.45) * 0.95;

    // console.log(coverageZoomPct);

    // console.log(this.plane.material.uniforms.uRadius.value);

    // ZOOM
    let communityEndPct = world.scrollController.communityEndPct;
    communityEndPct = Math.max(0, Math.min(1, communityEndPct));

    this.plane.material.uniforms.uZoom.value = communityEndPct;

    this.patches.update(communityEndPct);
  }
}
