export default class Config {
  constructor() {
    // console.log('Config :: constructor');
  }

  load(basePath, loadHandler) {
    // console.log('Config :: loadConfig');
    fetch(`${basePath}config/config.json`)
      .then((response) => response.json())
      .then((json) => {
        this.json = json;
        loadHandler && loadHandler();
      });
  }
}
