export default class ScrollController {
  constructor() {
    this.marker1 = document.querySelector('[data-gl="hero"]');
    this.marker2 = document.querySelector('[data-gl="coverage"]');
    this.marker3 = document.querySelector('[data-gl="coverage_zoom"]');
    this.marker4 = document.querySelector('[data-gl="community_start"]');
    this.marker5 = document.querySelector('[data-gl="community_end"]');

    // this.getPosition('marker1', this.marker1);
    // this.getPosition('marker2', this.marker2);
    // this.getPosition('marker3', this.marker3);
    // this.getPosition('marker4', this.marker4);
    // this.getPosition('marker5', this.marker5);
  }

  getPosition = (name, marker) => {
    console.log(name + ':');
    console.log(marker);
    const bounds = marker.getBoundingClientRect();
    console.log(bounds);
  };

  update = () => {
    // console.log(this.totalPct);
    // this.heroPct = this.followElemenet(this.marker1, null);
    // this.coveragePct = this.followElemenet(this.marker2, this.marker1);
    // this.coverageZoomPct = this.followElemenet(this.marker3, this.marker2);
    // this.communityStartPct = this.followElemenet(this.marker4, this.marker3);
    // this.communityEndPct = this.followElemenet(this.marker5, this.marker4);

    this.coveragePct = this.followElemenet(this.marker1, this.marker2);
    this.coverageZoomPct = this.followElemenet(this.marker2, this.marker3);
    this.communityEndPct = this.followElemenet(this.marker4, this.marker5);


    // console.log(kaka);
  };

  followElemenet = (topElement, bottomElement) => {
    if (!topElement || !bottomElement) {
      return 0;
    }

    const viewportHeight = window.innerHeight;

    let bounds = topElement.getBoundingClientRect();
    const topElementPosition = bounds.top + bounds.height / 2;

    bounds = bottomElement.getBoundingClientRect();
    const bottomElementPosition = bounds.top + bounds.height / 2;

    const distance = bottomElementPosition - topElementPosition;

    return -(topElementPosition - viewportHeight) / distance;
  };

  // followElemenet = (element, prevElement) => {
  //   // Get the relevant measurements and positions

  //   if (!element) {
  //     return 0;
  //   }

  //   const viewportHeight = window.innerHeight;

  //   let prevEnd = 0;
  //   if (prevElement) {
  //     const prevBounds = prevElement.getBoundingClientRect();
  //     const prevTop = prevElement.offsetTop;
  //     const prevHeight = prevBounds.height;
  //     prevEnd = prevTop + prevHeight;
  //   }

  //   const bounds = element.getBoundingClientRect();
  //   const elementOffsetTop = element.offsetTop;
  //   const elementTop = bounds.top;

  //   if (prevEnd === 0) {
  //     // console.log(bounds);
  //     // console.log(element.offsetTop);
  //   }

  //   return 1 - (elementTop - viewportHeight) / (elementOffsetTop - prevEnd);
  // };
}
