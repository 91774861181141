import gsap from 'gsap';
import { world } from '../../../World';
import Patch from './Patch';
import * as THREE from 'three';

export default class BluetoothPatch extends Patch {
  constructor(data) {
    super();

    const { id, long, lat } = data;
    this.data = data;

    const map = world.textureManager.bluetoothMap;

    this.wrapper.position.z = 1.75;

    const material = new THREE.SpriteMaterial({ map: map });
    const sprite = new THREE.Sprite(material);
    const scale = 0.25;
    sprite.scale.set(scale, scale, scale);
    this.wrapper.add(sprite);

    // const rotation = new THREE.Euler(THREE.MathUtils.degToRad(-long), THREE.MathUtils.degToRad(lat), 0, 'ZYX');
    const rotation = new THREE.Euler(THREE.MathUtils.degToRad(-lat), THREE.MathUtils.degToRad(long), 0, 'ZYX');
    this.rotation.copy(rotation);

    const item = sprite;
    item.userData.enabled = true;
    item.userData.click = this.clickHandler.bind(this);
    item.userData.rollOver = this.overHandler.bind(this);
    item.userData.rollOut = this.outHandler.bind(this);
    world.main.caster.addItem(item);
  }

  clickHandler() {
    // console.log('clickHandler');
    // console.log(this.idName);

    const coveragePct = world.scrollController.coveragePct;
    if (coveragePct > 0.5) {
      world.onBluetoothClick && world.onBluetoothClick(this.data);
    }
  }

  overHandler() {
    // console.log('Bluetooth :: overHandler');

    const coveragePct = world.scrollController.coveragePct;
    if (coveragePct > 0.5) {
      world.main.earth.globe.overHandler();
    }
  }

  outHandler() {
    // console.log('Bluetooth :: outHandler');

    const coveragePct = world.scrollController.coveragePct;
    if (coveragePct > 0.5) {
      world.main.earth.globe.outHandler();
    }
  }
}
