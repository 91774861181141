import gsap from 'gsap';
import * as THREE from 'three';
import { LineGeometry } from 'three/addons/lines/LineGeometry.js';
import { Line2 } from 'three/addons/lines/Line2.js';
import { LineMaterial } from 'three/addons/lines/LineMaterial.js';
import { world } from '../../../World';

// const radius = 1.6;

const activeColor = new THREE.Color('#008aff');
const inActiveColor = new THREE.Color('#61ff00');

export default class Line extends THREE.Group {
  constructor(start, end) {
    super();

    const v1 = this.getPosition(start.lat, start.long, 1.55);
    const vControl = this.getPosition((start.lat + end.lat) * 0.5, (start.long + end.long) * 0.5, 2.6);
    const v2 = this.getPosition(end.lat, end.long, 1.55);

    const curve = new THREE.QuadraticBezierCurve3(v1, vControl, v2);

    const positions = [];
    this.colors = [];

    this.colorPct = -1 + Math.random() * 3;

    this.divisions = 40;
    const point = new THREE.Vector3();
    const color = new THREE.Color();

    for (let i = 0, l = this.divisions; i < l; i++) {
      const t = i / (l - 1);
      // console.log(t);
      curve.getPoint(t, point);
      positions.push(point.x, point.y, point.z);

      color.setHSL(Math.random() * 360, 1.0, 0.5, THREE.SRGBColorSpace);
      this.colors.push(color.r, color.g, color.b);
    }

    const geometry = new LineGeometry();
    geometry.setPositions(positions);
    geometry.setColors(this.colors);

    this.material = new LineMaterial({
      transparent: true,
      vertexColors: true,
      color: 0xffffff,
      dashed: true,
      gapSize: 1,
      // dashScale: 3,
      dashSize: 1,
      // dashOffset: .5,
      // dashOffset: 0.1,
      linewidth: 0, // in world units with size attenuation, pixels otherwise
      // linewidth: 0.0035, // in world units with size attenuation, pixels otherwise

      // color: 0x00ff00,
      // opacity: 0,
    });

    let line = new Line2(geometry, this.material);
    line.computeLineDistances();
    line.scale.set(1, 1, 1);
    this.add(line);

    this.line = line;

    world.main.container.add(this);

    this.showPct = 0;
    this.send();
  }

  getPosition(lat, long, radius) {
    // console.log(lat, long);

    lat = THREE.MathUtils.degToRad(90 - lat);
    long = THREE.MathUtils.degToRad(long);

    return new THREE.Vector3().setFromSphericalCoords(radius, lat, long);
  }

  showSprite(position) {
    const material = new THREE.SpriteMaterial({ color: 'red' });
    const sprite = new THREE.Sprite(material);
    sprite.position.copy(position);
    this.add(sprite);
    sprite.scale.set(0.2, 0.2, 0.2);
  }

  show() {
    // this.material.linewidth = 0.0035;
    gsap.killTweensOf(this.material);
    gsap.to(this.material, {
      delay: Math.random() * 1,
      // opacity: 1,
      linewidth: 0.004,
      duration: 0.4,
      ease: 'power3.out',
    });
  }

  hide() {
    // this.material.linewidth = 0.0015;
    gsap.killTweensOf(this.material);
    gsap.to(this.material, {
      delay: Math.random() * 0.3,
      // opacity: 0,
      linewidth: 0,
      duration: 0.4,
      ease: 'power3.out',
    });
  }

  send() {
    gsap.killTweensOf(this);
    gsap.to(this, {
      delay: 3 + Math.random() * 6,
      showPct: 1,
      duration: 3,
      ease: 'sine.inOut',
      onComplete: this.unsend.bind(this),
    });
  }

  unsend() {
    gsap.killTweensOf(this);
    gsap.to(this, {
      delay: 2 + Math.random() * 2,
      showPct: 0,
      duration: 0.5,
      ease: 'power3.out',
      onComplete: this.send.bind(this),
    });
  }

  update() {
    let color = new THREE.Color();
    let i3;
    let linePct;

    this.colorPct += world.time.delta * 0.0011;

    if (this.colorPct > 2) {
      this.colorPct -= 3;
    }

    for (let i = 0; i < this.divisions; i++) {
      linePct = i / (this.divisions - 1);
      i3 = i * 3;

      let lerpFactor = (this.colorPct - linePct) * 1;
      if (lerpFactor < 0) lerpFactor *= -10;
      lerpFactor = Math.min(1, lerpFactor);

      color.lerpColors(activeColor, inActiveColor, lerpFactor);

      this.colors[i3 + 0] = color.r;
      this.colors[i3 + 1] = color.g;
      this.colors[i3 + 2] = color.b;
    }

    this.line.geometry.setColors(this.colors);

    //

    this.line.material.gapSize = 3 - this.showPct * 3;
    this.line.material.dashSize = this.showPct * 3;
  }
}
