import * as THREE from 'three';
import { world } from '../World';

export default class SceneManager {
  constructor(basePath) {
    this.ratio = 4 / 3;

    // this.environment = world.cubeTextureLoader
    //   .setPath(`${basePath}textures/environment/`)
    //   .load(['px.png', 'nx.png', 'py.png', 'ny.png', 'pz.png', 'nz.png']);
  }

  init() {
    const scene = new THREE.Scene();

    if (world.settings.debug) {
      // this.axesHelper = new THREE.AxesHelper();
      // scene.add(this.axesHelper);
      //debug items
      // this.items = new THREE.Group();
      // scene.add(this.items);
      // this.items.visible = world.settings.showHelpers;
      // this.axesHelper = new THREE.AxesHelper();
      // this.items.add(this.axesHelper);
      // this.scenePlane = new THREE.Mesh(
      //   new THREE.PlaneGeometry(2.5, 2.5 / this.ratio),
      //   new THREE.MeshBasicMaterial({ color: 0xffffff, wireframe: true })
      // );
      // this.items.add(this.scenePlane);
    }

    return scene;
  }
}
