import gsap from 'gsap';
import { world } from '../../World';
import * as THREE from 'three';
import Globe from './Globe';

export default class Earth extends THREE.Group {
  constructor() {
    super();

    world.main.container.add(this);

    //DESKTOP
    this.rotation.set(0, 0.4, 0.8);
    this.position.set(1, -1, 0);
    this.scale.set(1, 1, 1);

    this.size = {
      desktop: 1,
      mobile: 0.7,
    };

    this.pos = {
      desktop: {
        x: 1,
        y: -1,
        z: 0,
      },
      mobile: {
        x: 0.7,
        y: -1,
        z: 0,
      },
    };

    //MOBILE
    // this.position.set(.7, -1, 0);
    // this.scale.set(.75, .75, .75)

    this.wrapper = new THREE.Group();
    this.add(this.wrapper);

    this.globe = new Globe();
    this.wrapper.add(this.globe);

    this.initSlideTimeline();
    this.initZoomTimeline();
  }

  initSlideTimeline() {
    const tl = new gsap.timeline({ paused: true });

    const ease = 'power3.inOut';
    const duration = 10;
    // const scale = 0.75;

    //DESKTOP
    // tl.to(this.position, { duration, x: 0.5, y: 0.1, ease }, 0);
    // tl.to(this.pos, { duration, desktop: { x: 0.5, y: 0.1 }, mobile: { x: 0, y: -1 }, ease }, 0);
    tl.to(this.pos.desktop, { duration, x: 0.5, y: 0.1, ease }, 0);
    tl.to(this.pos.mobile, { duration, x: 0, y: -1, ease }, 0);
    tl.to(this.rotation, { duration, y: -0.6, z: -0.6, ease }, 0);
    // tl.to(this.scale, { duration, x: scale, y: scale, z: scale, ease }, 0);
    tl.to(this.size, { duration, desktop: 0.75, mobile: 0.7, ease }, 0);

    //MOBILE

    this.slideTimeline = tl;

    // tl.to(this.position, { duration, x: 0, y: -1, ease }, 0);
    // tl.to(this.rotation, { duration, y: -0.6, z: -0.6, ease }, 0);
    // tl.to(this.scale, { duration, x: scale, y: scale, z: scale, ease }, 0);
  }

  initZoomTimeline() {
    const tl = new gsap.timeline({ paused: true });

    const ease = 'power4.in';
    const duration = 10;
    // const scale = 2;

    // tl.to(this.position, { duration: 7, y: 0, x: 0, ease: 'sine.inOut' }, 0);
    tl.to(this.pos.desktop, { duration: 7, x: 0, y: 0, ease: 'sine.inOut' }, 0);
    tl.to(this.pos.mobile, { duration: 7, x: 0, y: 0, ease: 'sine.inOut' }, 0);
    tl.to(this.rotation, { duration, y: 0, z: 0, ease: 'sine.in' }, 0);
    // tl.to(this.scale, { duration: 10, x: scale, y: scale, z: scale, ease }, 0);
    tl.to(this.size, { duration, desktop: 2, mobile: 2, ease }, 0);

    this.zoomTimeline = tl;
  }

  playIntro() {
    this.globe.playIntro();
  }

  update() {
    this.globe.update();

    const mobilePct = world.sizes.mobilePct;
    const desktopPct = world.sizes.desktopPct;

    // console.log(mobilePct, desktopPct)

    //SIZE
    const scale = this.size.mobile * mobilePct + this.size.desktop * desktopPct;
    // console.log(scale);
    this.scale.set(scale, scale, scale);

    //POSITION
    const posX = this.pos.mobile.x * mobilePct + this.pos.desktop.x * desktopPct;
    const posY = this.pos.mobile.y * mobilePct + this.pos.desktop.y * desktopPct;
    const posZ = this.pos.mobile.z * mobilePct + this.pos.desktop.z * desktopPct;
    // console.log(posX, posY, posZ);
    this.position.set(posX, posY, posZ);

    //REST
    const coveragePct = world.scrollController.coveragePct;
    if (coveragePct > 0 && coveragePct <= 1) {
      this.slideTimeline.progress(coveragePct);
    }

    const coverageZoomPct = world.scrollController.coverageZoomPct;
    if (coverageZoomPct > 0 && coverageZoomPct <= 1) {
      this.zoomTimeline.progress(coverageZoomPct);
    }
    this.visible = coverageZoomPct <= 1.2;

    // console.log(coverageZoomPct);
  }
}
