import * as THREE from 'three';
import { world } from '../World';
import gsap from 'gsap';

import Earth from './earth/Earth';
import { Caster } from './Caster';
import Hexa from './hexa/Hexa';

export default class Main {
  constructor(basePath) {
    //console.log('Main :: constructor');
  }

  init() {
    //console.log('Main :: init');
    this.inited = true;

    this.caster = new Caster();

    this.container = new THREE.Group();
    world.scene.add(this.container);

    this.earth = new Earth();

    this.hexa = new Hexa();
  }

  playIntro() {
    // console.log('Main :: playIntro');

    this.earth.playIntro();
  }

  resize() {
    if (this.inited) {
      this.hexa.resize();
    }
  }

  update() {
    if (this.inited) {
      this.caster.update();
      this.earth.update();
      this.hexa.update();
    }
  }
}
