import * as dat from 'lil-gui';

export default class Settings {
  constructor() {
    this.debug = false; // show/hide GUI and other debug items
    this.showHelpers = true; // show/hide all helpers on start
    // this.usePostprocessing = true; // enable/disable post processing
    this.showBounds = true; // show bounds of Cannon physics objects
  }

  init() {
    if (this.debug) {
      const gui = new dat.GUI({ width: 350 });
      gui.close();

      // const folder = gui.addFolder('Settings');
      // folder.add(this, 'usePostprocessing').name('use postprocessing');
      // folder.close();

      return gui;
    }
  }
}
