import gsap from 'gsap';
import { world } from '../../../World';
import Patch from './Patch';
import * as THREE from 'three';

export default class PicturePatch extends Patch {
  constructor({ cycle, texture, long, lat }) {
    super();

    this.active = false;
    this.currentCycle = 0;
    this.cycle = cycle;

    const mapData = world.textureManager.getTexture(texture);

    this.wrapper.position.z = 1.75 + mapData.ratio;

    const material = new THREE.SpriteMaterial({ map: mapData.texture });
    const sprite = new THREE.Sprite(material);
    const scale = 0.3 - (mapData.ratio - 1) * 0.03;
    sprite.scale.set(scale * mapData.ratio, scale, scale);
    this.wrapper.add(sprite);

    // const rotation = new THREE.Euler(THREE.MathUtils.degToRad(-long), THREE.MathUtils.degToRad(lat), 0, 'ZYX');
    const rotation = new THREE.Euler(THREE.MathUtils.degToRad(-lat), THREE.MathUtils.degToRad(long), 0, 'ZYX');
    this.rotation.copy(rotation);
  }

  setActive(active) {
    this.active = active;
    this.setVisibility();
  }

  setCycle(currentCycle) {
    this.currentCycle = currentCycle;
    this.setVisibility();
  }

  setVisibility() {
    if (this.cycle === this.currentCycle && this.active) {
      this.show();
    }else{
      this.hide();
    }
  }
}
