import * as THREE from 'three';
import { world } from '../World';

export class Caster {
  constructor() {
    this.isEnabled = true;
    this.items = [];
    this.scale = 1;

    this.pointer = new THREE.Vector2();
    this.raycaster = new THREE.Raycaster();

    window.addEventListener('mousemove', this.mousemoveHandler.bind(this));
    window.addEventListener('click', this.clickHandler.bind(this));

    window.addEventListener('touchstart', this.touchmoveHandler.bind(this));
  }

  setScale(pct) {
    // console.log('Caster :: setScale');
    // console.log(pct);
    this.scale = pct;
  }

  setItems(items) {
    this.items = items;
  }

  addItem(item) {
    this.items.push(item);
    // console.log(this.items);
  }

  setCallback(callback) {
    this.callback = callback;
  }

  enable() {
    this.isEnabled = true;
    this.pointer.x = this.pointer.y = -100;
  }

  disable() {
    this.isEnabled = false;
  }

  clickHandler() {
    if (this.isEnabled && this.activeItem) {
      this.activeItem.userData.click && this.activeItem.userData.click(this.activeItem);
      this.callback && this.callback(this.activeItem);
    }
  }

  mousemoveHandler(e) {
    if (this.isEnabled) {
      this.pointer.x = (e.clientX / window.innerWidth) * 2 - 1;
      this.pointer.y = -((e.clientY / window.innerHeight) * 2 - 1);
    }
  }

  touchmoveHandler(e) {
    if (this.isEnabled) {
      var touch = e.touches[0];

      if (touch) {
        this.pointer.x = (touch.clientX / window.innerWidth) * 2 - 1;
        this.pointer.y = -((touch.clientY / window.innerHeight) * 2 - 1);
      }
    }
  }

  update() {
    const pointer = { x: this.pointer.x / this.scale, y: this.pointer.y / this.scale };
    if (this.isEnabled && pointer.x && pointer.y && this.items) {
      this.raycaster.setFromCamera(pointer, world.camera);
      const intersects = this.raycaster.intersectObjects(this.items);

      if (intersects.length > 0) {
        const item = intersects[0].object;
        // console.log(item);

        if (item.userData.enabled) {
          if (item != this.activeItem) {
            this.rollOut();
            this.rollOver(item);
          }

          this.activeItem = item;
        }
      } else if (this.activeItem) {
        this.rollOut();

        this.activeItem = null;
      }
    }
  }

  rollOver(item) {
    // world.postProcessing.outlinePass.selectedObjects = [item];

    // document.body.style.cursor = 'pointer';
    item.userData.rollOver && item.userData.rollOver();
  }

  rollOut() {
    // world.postProcessing.outlinePass.selectedObjects = [];

    // document.body.style.cursor = 'auto';
    if (this.activeItem && this.activeItem.userData.rollOut) {
      this.activeItem.userData.rollOut();
    }
  }
}
