class Mouse {
  constructor(allowTouch = false) {
    this.allowTouch = allowTouch;

    this.x = this.slowX = this.xMax = 0;
    this.y = this.slowY = this.yMax = 0;
    this.pctX = this.slowPctX = 0;
    this.pctY = this.slowPctY = 0;
    this.isInited = this.isMobile = false;

    this.start = Date.now();
    this.current = this.start;
    this.delta = 16; //FPS: 1000 / 60
  }

  init() {
    window.addEventListener('mousemove', this.handleMouseMove.bind(this));
    window.addEventListener('touchstart', this.handleTouchStart.bind(this));
    if (this.allowTouch) {
      window.addEventListener('touchmove', this.handleTouchmove.bind(this));
      window.addEventListener('touchend', this.handleTouchmove.bind(this));
    }

    window.addEventListener('resize', this.resizeHandler.bind(this));
    this.resizeHandler();

    this.loop();
  }

  resizeHandler() {
    this.xMax = window.innerWidth;
    this.yMax = window.innerHeight;

    this.x = Math.min(this.xMax, this.x);
    this.y = Math.min(this.yMax, this.y);

    if (!this.isInited) {
      this.x = this.slowX = this.xMax * 0.5;
      this.y = this.slowY = this.yMax * 0.5;
      this.pctX = this.slowPctX = 0.5;
      this.pctY = this.slowPctY = 0.5;
    }
  }

  handleTouchStart(event) {
    this.isMobile = true;

    if (this.allowTouch) {
      this.isInited = true;
      this.handleTouchmove(event);
    }
  }

  handleTouchmove(event) {
    var touch = event.touches[0];
    if (touch) {
      this.x = touch.clientX;
      this.y = touch.clientY;
    }
  }

  handleMouseMove(event) {
    if (!this.isMobile || (this.isMobile && this.allowTouch)) {
      this.x = event.clientX;
      this.y = event.clientY;
      this.isInited = true;
    }
  }

  loop() {
    const currentTime = Date.now();
    this.delta = currentTime - this.current;
    this.current = currentTime;

    if (this.isInited) {
      this.slowX += (this.x - this.slowX) * 0.01 * this.delta;
      this.slowY += (this.y - this.slowY) * 0.01 * this.delta;

      this.pctX = this.x / this.xMax;
      this.pctY = this.y / this.yMax;

      this.slowPctX = this.slowX / this.xMax;
      this.slowPctY = this.slowY / this.yMax;
    }

    window.requestAnimationFrame(this.loop.bind(this));
  }
}

const instance = new Mouse(true);
export default instance;
