export default class Sizes {
  constructor() {
    this.resize();
  }

  resize() {
    this.desktopPct = (window.innerWidth - 490) / 50;
    this.desktopPct = Math.max(0, Math.min(1, this.desktopPct));
    this.mobilePct = 1 - this.desktopPct;
    // console.log(this.mobilePct, this.desktopPct);

    this.width = window.innerWidth;
    this.height = window.innerHeight;

    this.pixelRatio = Math.min(window.devicePixelRatio, 2);
  }
}
