import gsap from 'gsap';
import { world } from '../../../World';
import Patch from './Patch';
import * as THREE from 'three';

export default class CityPatch extends Patch {
  constructor(data) {
    super();

    const { city, long, lat } = data;
    this.data = data;

    const map = world.textureManager.cityMap;

    this.wrapper.scale.set(0, 0, 0);
    this.wrapper.position.z = 1.55;

    const materialTop = new THREE.SpriteMaterial({ map: map });
    const spriteTop = new THREE.Sprite(materialTop);
    let scale = 0.03 + Math.random() * 0.03;
    spriteTop.scale.set(scale, scale, scale);
    this.wrapper.add(spriteTop);

    const materialBot = new THREE.SpriteMaterial({ map: map, transparent: true, opacity: 0.2 });
    const spriteBot = new THREE.Sprite(materialBot);
    scale = 0.06 + Math.random() * 0.03;
    spriteBot.scale.set(scale, scale, scale);
    this.wrapper.add(spriteBot);

    // const rotation = new THREE.Euler(THREE.MathUtils.degToRad(-long), THREE.MathUtils.degToRad(lat), 0, 'ZYX');
    const rotation = new THREE.Euler(THREE.MathUtils.degToRad(-lat), THREE.MathUtils.degToRad(long), 0, 'ZYX');
    this.rotation.copy(rotation);

    const item = spriteTop;
    item.userData.enabled = true;
    item.userData.click = this.clickHandler.bind(this);
    item.userData.rollOver = this.overHandler.bind(this);
    item.userData.rollOut = this.outHandler.bind(this);
    world.main.caster.addItem(item);
  }

  clickHandler() {
    // console.log('clickHandler');

    world.onCityClick && world.onCityClick(this.data);
  }

  overHandler() {
    // console.log('CityPatch :: overHandler');
    // console.log(this.city);
    world.main.earth.globe.overHandler();
    world.onCityOver && world.onCityOver(this.data);
  }

  outHandler() {
    // console.log('CityPatch :: outHandler');
    world.main.earth.globe.outHandler();
    world.onCityOut && world.onCityOut(this.data);
  }
}
