// See /readme.md for set-up and bundling information

// Import the World class instance from the library
// (replace it with the bundled version which can be found in /build_library/world.js)
import { world } from './lib/World';

/*
 * INITIALIZE WORLD
 */

// Initialize the World class instance with these constructor parameters:
// - canvas: the canvas html element to which the WebGL content is rendered
// - basePath: the path of the assets
// - onLoad (optional): this method is called when all assets have been loaded
// - onLoadProgress (optional): this method is called when the assets are being loaded
// - onIntroFinished (optional): this method is called when the intro animation has stopped
const canvas = document.querySelector('canvas.webgl');
world.init(canvas, '/assets/', onLoad, onProgress, onIntroFinished);

function onLoad() {
  console.log('onLoad');
  //start playing the intro animation
  world.playIntro();
}

// A parameter is passed to this callback with a percentage of the assets that finished loading (0 to 1)
function onProgress(loadProgress) {
  console.log('onProgress, loadProgress: ' + loadProgress);
}

function onIntroFinished() {
  console.log('onIntroFinished');
}

/*
 * CYCLE PICTURE PATCHES
 */

let num = 0;

setInterval(() => {
  num++;
  console.log(num % 3);
  world.cyclePictures(num % 3);
}, 4500);

/*
 * BLUETOOTH PATCH CALLBACKS
 */

world.addBluetoothCallbacks(onBluetoothClick);

function onBluetoothClick(data) {
  console.log('onBluetoothClick');
  console.log(data);
}

/*
 * CITY PATCH CALLBACKS
 */

world.addCityCallbacks(onCityClick, onCityOver, onCityOut);

function onCityClick(data) {
  console.log('onCityClick');
  console.log(data);
}

function onCityOver(data) {
  console.log('onCityOver');
  console.log(data);
}

function onCityOut(data) {
  console.log('onCityOut');
  console.log(data);
}

/*
 * LOCAL TESTS
 */

const enableHandler = () => {
  console.log('enableHandler');
};

const disableHandler = () => {
  console.log('disableHandler');
};

document.querySelector('#enableButton').addEventListener('click', enableHandler);
document.querySelector('#disableButton').addEventListener('click', disableHandler);


const removeHandler = () => {
  console.log('removeHandler');
};

const addHandler = () => {
  console.log('addHandler');
};

document.querySelector('#removeButton').addEventListener('click', removeHandler);
document.querySelector('#addButton').addEventListener('click', addHandler);
