import * as THREE from 'three';
import { world } from '../World';

export default class RendererManager {
  constructor() {}

  init() {
    // renderer
    const renderer = new THREE.WebGLRenderer({
      canvas: world.canvas,
      antialias: true,
      powerPreference: 'high-performance',
      alpha: true,
    });

    renderer.physicallyCorrectLights = true;
    renderer.toneMapping = THREE.NoToneMapping;
    renderer.toneMappingExposure = 1.1;
    renderer.shadowMap.enabled = false;
    // renderer.shadowMap.type = THREE.PCFShadowMap;
    renderer.setClearColor(0x000000, 0);

    // gui
    if (world.settings.debug) {
      const folder = world.gui.addFolder('Renderer');
      folder.add(renderer, 'toneMapping', {
        No: THREE.NoToneMapping,
        Linear: THREE.LinearToneMapping,
        Reinhard: THREE.ReinhardToneMapping,
        Cineon: THREE.CineonToneMapping,
        ACESFilmic: THREE.ACESFilmicToneMapping,
      });
      folder.add(renderer, 'toneMappingExposure').min(0).max(2).step(0.001);
      folder.close();
    }

    return renderer;
  }

  setDarkMode(isDark) {
    isDark ? world.renderer.setClearColor(0x000000, 1) : world.renderer.setClearColor(0xffffff, 1);
  }

  resize() {
    world.renderer.setSize(world.sizes.width, world.sizes.height);
    world.renderer.setPixelRatio(world.sizes.pixelRatio);
  }

  update() {
    world.renderer.render(world.scene, world.camera);
  }
}
