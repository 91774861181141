import gsap from 'gsap';
import { world } from '../../World';
import * as THREE from 'three';
import Patch from './Patch';

export default class Patches extends THREE.Group {
  constructor() {
    super();

    this.endPatches = [];
    let counter = 0;
    world.config.json.endPatches.map((data) => {
      const mapData = world.textureManager.getTexture(data.texture);

      const patch = new Patch(counter, mapData.texture);
      this.add(patch);

      counter++;
    });
  }

  update(zoomPct) {
    // this.position.z = (1 - zoomPct) * 5.15;
    this.position.z = (1 - zoomPct) * 5.5;
  }
}
