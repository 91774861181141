import * as THREE from 'three';
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
// import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import Stats from 'three/examples/jsm/libs/stats.module';
import mouse from './core/Mouse';

import Settings from './core/Settings';
import Sizes from './core/Sizes';
import SceneManager from './core/SceneManager';
import CameraManager from './core/CameraManager';
import RendererManager from './core/RendererManager';
import Time from './core/Time';

import Main from './main/Main';
import TextureManager from './core/TextureManager';
import Config from './core/Config';
import ScrollController from './core/ScrollController';

class World {
  constructor() {
    mouse.init();
    this.state = 'presentation';
    this.isDarkColorMode = false;

    this.scrollPct = 0;
    this.scrollPctEnd = 0;
  }

  init(canvas, basePath, onLoad, onProgress, onIntroFinished) {
    // save canvas
    this.canvas = canvas;
    this.onIntroFinished = onIntroFinished;
    this.basePath = basePath;

    // init loaders
    this.initLoaders(basePath, onLoad, onProgress);

    // config
    this.config = new Config();
    this.config.load(basePath, this.onConfigLoaded.bind(this));
  }

  // setScale(pct) {
  //   this.main.caster.setScale(pct);
  // }

  addBluetoothCallbacks(onBluetoothClick) {
    this.onBluetoothClick = onBluetoothClick;
  }

  addCityCallbacks(onCityClick, onCityOver, onCityOut) {
    this.onCityClick = onCityClick;
    this.onCityOver = onCityOver;
    this.onCityOut = onCityOut;
  }

  onConfigLoaded() {
    // scroll controller
    this.scrollController = new ScrollController();

    // setting
    this.settings = new Settings();
    this.gui = this.settings.init();

    // sizes
    this.sizes = new Sizes();

    // scene
    this.sceneManager = new SceneManager(this.basePath);
    this.scene = this.sceneManager.init();

    // camera
    this.cameraManager = new CameraManager();
    this.camera = this.cameraManager.init();

    // renderer
    this.rendererManager = new RendererManager();
    this.renderer = this.rendererManager.init();

    // textures
    this.textureManager = new TextureManager(this.basePath);

    // time
    this.time = new Time(this.update.bind(this));

    // main
    this.main = new Main(this.basePath);

    //caster

    //stats
    if (this.settings.debug) {
      this.stats = Stats();
      document.body.appendChild(this.stats.dom);
    }

    // resize
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
  }

  initLoaders(basePath, onLoad, onProgress) {
    this.loadingManager = new THREE.LoadingManager();
    this.textureLoader = new THREE.TextureLoader(this.loadingManager);
    // this.cubeTextureLoader = new THREE.CubeTextureLoader(this.loadingManager);

    // const dracoLoader = new DRACOLoader();
    // dracoLoader.setDecoderPath(`${basePath}draco/`);
    // this.gltfLoader = new GLTFLoader(this.loadingManager);
    // this.gltfLoader.setDRACOLoader(dracoLoader);

    this.loadingManager.onLoad = () => {
      this.main.init();

      onLoad && onLoad();
    };

    this.loadingManager.onProgress = (url, itemsLoaded, itemsTotal) => {
      const loadProgress = itemsLoaded / itemsTotal;

      onProgress && onProgress(loadProgress);
    };
  }

  playIntro() {
    // this.cameraManager.startAnimation(this.enable.bind(this));
    this.main.playIntro();

    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(this.enable.bind(this), 3000);
  }

  cyclePictures(num) {
    this.main.earth.globe.patches.cyclePictures(num);
  }

  enable() {
    //console.log('enable');
    // document.querySelector('.scrollWrapper').style.pointerEvents = 'all';
    // this.isEnabled = true;
    this.onIntroFinished && this.onIntroFinished();
  }

  resize() {
    this.sizes.resize();
    this.cameraManager.resize();
    this.main.resize();
    this.rendererManager.resize();
  }

  update() {
    this.cameraManager.update();
    this.main.update();
    this.scrollController.update();

    // this.scrollPct += (this.scrollPctEnd - this.scrollPct) * 0.01 * this.time.delta;
    //console.log(this.scrollPct);

    // if (this.isEnabled) {
    //   instance.cameraManager.scroll(this.scrollPct);
    // }

    if (this.stats) {
      this.stats.update();
    }

    this.rendererManager.update();
  }
}

const instance = new World();
export { instance as world };
